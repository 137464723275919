import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import Container from "../components/Container"
import PageHeader from "../components/PageHeader"
import PageContent from "../components/PageContent"

const Contact = ({ data }) => {
  const page = data.pageContent
  const bgImage = data.bgImage

  return (
    <Layout>
      <Seo title="Contact Joss Cannon" />
      <BackgroundImage
        image={bgImage.theImage.asset.gatsbyImageData}
        alt={bgImage.altText}
      />
      <PageHeader dark />
      <PageContent dark>
        <Container>
          <FlexContainer>
            {page.mainImage != null && (
              <MainImage
                image={page.mainImage.asset.gatsbyImageData}
                alt="{page.altText}"
              />
            )}
            <ContentContainer>
              <Title>Contact Joss Cannon</Title>
              <ContactsContainer>
                <ContactItem>
                  <ContactLink href="mailto:JossCannonWrites@gmail.com">
                    <StyledSvg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32 32"
                      width="24px"
                      height="24px"
                    >
                      <path
                        fill="currentColor"
                        d="M 16.1875 4 C 8.8227525 3.8922232 2.9146119 10.485994 4.15625 18.03125 C 4.9981586 23.151675 9.2050458 27.181379 14.34375 27.875 C 17.808627 28.342845 21.04781 27.343076 23.5 25.375 L 22.25 23.8125 C 20.20419 25.454424 17.512873 26.300405 14.59375 25.90625 C 10.338454 25.331871 6.8523414 21.952325 6.15625 17.71875 C 5.1078881 11.348006 9.9909975 5.9097768 16.15625 6 C 21.411045 6.077063 25.840449 10.43502 26 15.6875 C 26.0035 15.80006 26 15.909499 26 16.03125 C 25.992 18.234184 24.20805 20.000804 22 20 C 20.883008 19.999326 20 19.115742 20 18 L 20 17.5 L 20 14.5 L 20 10 L 18 10 L 18 10.78125 C 17.281448 10.294583 16.427539 10 15.5 10 C 13.026562 10 11 12.026562 11 14.5 L 11 17.5 C 11 19.973438 13.026562 22 15.5 22 C 16.847912 22 18.048165 21.385525 18.875 20.4375 C 19.60945 21.371627 20.728798 21.999233 22 22 C 25.28395 22.0012 27.988022 19.320316 28 16.03125 C 28 15.899001 28.0045 15.76844 28 15.625 C 27.807551 9.2894804 22.524705 4.0929369 16.1875 4 z M 15.5 12 C 16.892562 12 18 13.107438 18 14.5 L 18 17.5 C 18 18.892562 16.892562 20 15.5 20 C 14.107438 20 13 18.892562 13 17.5 L 13 14.5 C 13 13.107438 14.107438 12 15.5 12 z"
                      />
                    </StyledSvg>
                    JossCannonWrites@gmail.com
                  </ContactLink>
                </ContactItem>
                <ContactItem>
                  <ContactLink
                    href="https://www.instagram.com/josscannonwriter/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <StyledSvg
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      width="24px"
                      height="24px"
                    >
                      <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z" />
                    </StyledSvg>
                    josscannonwriter
                  </ContactLink>
                </ContactItem>
              </ContactsContainer>
            </ContentContainer>
          </FlexContainer>
        </Container>
      </PageContent>
    </Layout>
  )
}

export default Contact

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: stretch;
`

const MainImage = styled(GatsbyImage)`
  display: none;
  width: 100%;
  max-height: 60vh;
  border-radius: 4px;
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    width: 60%;
    max-height: 50vmax;
  }

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    display: block;
    width: 40%;
    flex-shrink: 0;
    flex-grow: 0;
  }
`
const BackgroundImage = styled(GatsbyImage)`
  position: fixed;
  top: 0;
  left: 0;
  max-height: 60vh;
  min-height: 30vh;
  width: 100%;
`

const ContentContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 2.5rem;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    text-align: left;
    width: 50%;
    margin-left: 3rem;
    flex-shrink: 1;
    flex-grow: 1;
  }
`

const Title = styled.h3`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.primaryLight};
  margin-bottom: 0.75rem;

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    font-size: 1.75rem;
    margin-bottom: 2rem;
  }
`
const ContactsContainer = styled.div`
  text-align: left;
  margin-top: 3rem;
  padding: 0 1rem;

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    margin-top: 5rem;
    padding: 0;
  }
`

const ContactItem = styled.div`
  margin: 1rem 0;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    margin-top: 2rem;
    text-align: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    text-align: left;
  }
`

const StyledSvg = styled.svg`
  vertical-align: middle;
  margin-right: 0.5rem;
`

const ContactLink = styled.a`
  font-size: 1.2rem;
  display: inline-block;
  color: ${({ theme }) => theme.primaryLight};

  &:hover {
    color: ${({ theme }) => theme.primaryHover};
  }

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    font-size: 1.5rem;
  }
`

export const query = graphql`
  query ContactPage {
    pageContent: sanityPage(slug: { current: { eq: "contact" } }) {
      mainImage {
        asset {
          gatsbyImageData
        }
      }
      slug {
        current
      }
      title
      _rawBody
      altText
    }
    bgImage: sanitySingleImage(Id: { eq: "blue-sky-clouds" }) {
      theImage {
        asset {
          gatsbyImageData
        }
      }
      altText
    }
  }
`
